<template>
  <div>
    <div class="page-label">兑换提现</div>
    <div class="exchange-con-wrap">
      <b-container fluid>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="我要提现" name="first">
            <div class="withdrawal-info">
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="可用金额:">
                  <span class="total-amount">¥32356.00</span>
                </el-form-item>
                <el-form-item label="提现金额:">
                  <div class="amount-wrap">
                    <el-input v-model="form.WithdrawalAmount" placeholder="输入提现金额"></el-input>
                    <el-button size="medium">全部提现</el-button>
                  </div>
                </el-form-item>
                <el-form-item label="收款账户:">
                  <el-input v-model="form.account" placeholder="输入收款账户"></el-input>
                </el-form-item>
                <el-form-item label="交易密码:">
                  <el-input type="password" v-model="form.password" autocomplete="off" placeholder="输入交易密码"></el-input>
                </el-form-item>
                <el-form-item label="短信通知:">
                  <el-input v-model="form.smsNotification" placeholder="输入手机号码"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="theme" @click="submitForm('form')">提交申请</el-button>
                </el-form-item>
              </el-form>  
            </div>
            <div class="table-wrap withdrawal">
              <div class="tips">
                <i class="el-icon-warning"></i>
                每笔提现申请必须经过财务审核通过之后才能给您入账，审核时间：3个工作日内。
              </div>
              <table>
                <thead>
                  <tr>
                    <th>提现账号</th>
                    <th>收款账户</th>
                    <th>提交时间</th>
                    <th>状态</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="items-wrap">
                        <div class="item-wrap">
                          <span class="label">提现账号：</span>
                          <span class="val">13751081328</span>
                        </div>
                        <div class="item-wrap">
                          <span class="label">提现金额：</span>
                          <span class="val-amount">100000.00元</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="items-wrap">
                        <div class="item-wrap">
                          <span class="label">收款账号：</span>
                          <span class="val">6226 9003 0588 8888</span>
                        </div>
                        <div class="item-wrap">
                          <span class="label">开户名：</span>
                          <span class="val">深圳市装点造化科技有限公司</span>
                        </div>
                        <div class="item-wrap">
                          <span class="label">开户机构：</span>
                          <span class="val">交通银行深圳分行</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="item">2021-09-14  18:21</div>
                    </td>
                    <td>
                      <div class="item under-review">审核中</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="items-wrap">
                        <div class="item-wrap">
                          <span class="label">提现账号：</span>
                          <span class="val">13751081328</span>
                        </div>
                        <div class="item-wrap">
                          <span class="label">提现金额：</span>
                          <span class="val-amount">100000.00元</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="items-wrap">
                        <div class="item-wrap">
                          <span class="label">收款账号：</span>
                          <span class="val">6226 9003 0588 8888</span>
                        </div>
                        <div class="item-wrap">
                          <span class="label">开户名：</span>
                          <span class="val">深圳市装点造化科技有限公司</span>
                        </div>
                        <div class="item-wrap">
                          <span class="label">开户机构：</span>
                          <span class="val">交通银行深圳分行</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="item">2021-09-14  18:21</div>
                    </td>
                    <td>
                      <div class="item approved">审核通过</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="paging-wrap">
                <div class="paging-con">
                  <el-pagination
                    background
                    layout="prev, pager, next, jumper"
                    :pager-count="5"
                    prev-text="上一页"
                    next-text="下一页"
                    :total="100">
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我要兑换" name="second">
            <div class="exchange-wrap">
              <div class="exchange-info-wrap">
                <span class="label-title">我的e币</span>
                <span class="quota">6350</span>
                <div class="inp-wrap">
                  <input type="text" name="eCoin" value="" placeholder="输入兑换数量" />
                </div>
                <i class="el-icon-sort">
                  <span>兑换RMB</span>
                </i>
                <div class="inp-wrap">
                  <input type="text" name="amount" value="" />
                </div>
                <span class="quota">¥28000.00</span>
                <span class="label-title">可用金额</span>
              </div>
              <el-button class="exchange-btn">兑换</el-button>
              <div class="tips">*e币超出2000部分方可兑换RMB，且兑换需要10的倍数</div>
            </div>
            <div class="table-wrap exchange">
              <table>
                <thead>
                  <tr>
                    <th>兑换时间</th>
                    <th>兑换数量</th>
                    <th>剩余数量</th>
                    <th>备注</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="item time">2021-08-31 17:39</div>
                    </td>
                    <td>
                      <div class="item">1000</div>
                    </td>
                    <td>
                      <div class="item">6350</div>
                    </td>
                    <td>
                      <div class="item">e币兑换[父订单号=MYD20210913012345]，成功兑换为可用金额：+100元</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item time">2021-08-31 17:39</div>
                    </td>
                    <td>
                      <div class="item">1000</div>
                    </td>
                    <td>
                      <div class="item">6350</div>
                    </td>
                    <td>
                      <div class="item">e币兑换[父订单号=MYD20210913012345]，成功兑换为可用金额：+100元</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="paging-wrap">
                <div class="paging-con">
                  <el-pagination
                    background
                    layout="prev, pager, next, jumper"
                    :pager-count="5"
                    prev-text="上一页"
                    next-text="下一页"
                    :total="100">
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </b-container>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        activeName: 'first',
        form: {}
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      submitForm(form){
        console.log(form)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/assetManagement/exchange/exchange";
</style>
